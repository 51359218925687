import React, { useState } from 'react'
import '../styles/Contact.css'

export default function Contact() {
  const [copied, setCopied] = useState(false)

  function copyToClipboard() {
    navigator.clipboard.writeText('race.ouzi@gmail.com')
    setCopied(true)
  }

  return (
    <main>
        <div className='contact-copy' onClick={copyToClipboard}>race.ouzi@gmail.com</div>
        <p className={`contact-copy-message ${copied ? 'visible' : 'invisible'}`}>Email copied to clipboard</p>
    </main>
  )
}

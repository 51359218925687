import React from 'react'
import CONFIG from "../settings/config.js"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { brands } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used
import "../styles/Footer.css"

export default function Footer(props) {
    const {theme} = props

    const icons = {
        facebook: <FontAwesomeIcon icon={brands("facebook")} size='lg' />,
        instagram: <FontAwesomeIcon icon={brands("instagram")} size='lg' />,
        twitter: <FontAwesomeIcon icon={brands("twitter")} size='lg' />,
        snapchat: <FontAwesomeIcon icon={brands("snapchat")} size='lg' />,
        soundcloud: <FontAwesomeIcon icon={brands("soundcloud")} size='lg' />,
        youtube: <FontAwesomeIcon icon={brands("youtube")} size='lg' />,
        spotify: <FontAwesomeIcon icon={brands("spotify")} size='lg' />,
        apple: <FontAwesomeIcon icon={brands("apple")} size='lg' />,
    }

    return (
      <footer className={`${theme === 'dark' ? 'bgcolor-black' : 'bgcolor-white'}`}>
        <ul className='socials'>
            {CONFIG.WEBSITE.MEDIAS.map((v) => <li key={v.NAME}><a href={v.LINK} target="_blank" className={`${theme === 'dark' ? 'color-white' : 'color-black'}`}>{icons[v.NAME]}</a></li>)}
        </ul>
      </footer>
    )
}

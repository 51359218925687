import './App.css';
import './styles/Main.css';
import Header from './components/Header';
import Footer from './components/Footer';
import { Outlet } from "react-router-dom";
import { useLocation } from 'react-router-dom';

function App() {
  const location = useLocation()
  
  function getFooterTheme() {
    if (location.pathname === '/') return 'dark'
    else return 'light'
  }

  return (
    
    
    <div className="App">
        <Header />
        <Outlet />
        <Footer theme={getFooterTheme()} />
    </div>
  );
}

export default App;

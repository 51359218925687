import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands, icon } from '@fortawesome/fontawesome-svg-core/import.macro' 

const SOCIAL = {
    FACEBOOK: {LINK: "", TAG: "@raceouzi", NAME: "facebook"},
    INSTAGRAM: {LINK: "https://www.instagram.com/raceouzi", TAG: "@raceouzi", NAME: "instagram"},
    SNAPCHAT: {LINK: "", TAG: "@raceouzi", NAME: "snapchat"},
    SOUNDCLOUD: {LINK: "https://soundcloud.com/raceouzi", TAG: "@raceouzi", NAME: "soundcloud"},
    TWITTER: {LINK: "https://twitter.com/raceouzi", TAG: "@raceouzi", NAME: "twitter"},
    YOUTUBE: {LINK: "https://www.youtube.com/raceouzi", TAG: "@raceouzi", NAME: "youtube"},
    SPOTIFY: {LINK: "https://open.spotify.com/artist/112lK2jH2bBsofzGtEtyLp", TAG: "@raceouzi", NAME: "spotify"},
    APPLE: {LINK: "https://music.apple.com/us/artist/race-ouzi/1675406053", TAG: "@raceouzi", NAME: "apple"}
}

const WEBSITE = {
    TITLE: "RACE OUZI OFFICIAL WEBSITE",
    NAME: "race ouzi",
    DESCRIPTION: "Official race ouzi website. Stay tune for new singles coming out soon!",
    ICON_LINK: "./favicon.ico",
    LOGO_192_LINK: "./logo192.png",
    LOGO_512_LINK: "./logo512.png",

    NAV: [
        ["home", "/"],
        ["contact", "/contact"],
        ["services", "/services"],
        ["merch", "/merch"]
    ],
    MEDIAS: [
        SOCIAL.INSTAGRAM, SOCIAL.YOUTUBE, SOCIAL.SOUNDCLOUD, SOCIAL.SPOTIFY, SOCIAL.APPLE
    ]
}

const PROMOTED = {
    VIDEO: "https://www.youtube.com/embed/8PKNK4N9IJE?autoplay=1",
    DISTROKID: {
        FIRST : "",
        OTHERS: [
            "",
            ""
        ]
    }
}

const CONFIG = {WEBSITE, SOCIAL, PROMOTED};
export default CONFIG
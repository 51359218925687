import React, { useEffect, useState } from 'react'
import '../styles/Header.css'

import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, brands } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used
import CONFIG from "../settings/config.js"

export default function Header() {

    const [scrollPosition, setScrollPosition] = useState(0);
    const handleScroll = () => {
        const position = window.pageYOffset;
        setScrollPosition(position);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const [open, setOpen] = useState(false)
    const onClick = () => {
        let bool = !open
        setOpen(bool)
    }
    const close = () => {
        setOpen(false)
    }

    const icons = {
        facebook: <FontAwesomeIcon icon={brands("facebook")} />,
        instagram: <FontAwesomeIcon icon={brands("instagram")} />,
        twitter: <FontAwesomeIcon icon={brands("twitter")} />,
        snapchat: <FontAwesomeIcon icon={brands("snapchat")} />,
        soundcloud: <FontAwesomeIcon icon={brands("soundcloud")} />,
        youtube: <FontAwesomeIcon icon={brands("youtube")} />
    }

    return (
        <header className={`${scrollPosition > 40 ? ' nav-white-blur' :''}`}>
            <nav className='nav' >
                <ul className='nav-c'>
                {CONFIG.WEBSITE.NAV.map((v, i) => {
                    if (i < CONFIG.WEBSITE.NAV.length/2) {
                        return <li key={v[0]}><Link className='nav-link' to={v[1]}>{v[0]}</Link></li>
                    }
                })}
                </ul>
                {<a href='/'><img className={`${scrollPosition > 40 ? 'lil-logo' : 'big-logo'}`} src='./logo.png' /></a>} 
                <ul className='nav-c'>
                {CONFIG.WEBSITE.NAV.map((v, i) => {
                    if (i >= CONFIG.WEBSITE.NAV.length/2) {
                        return <li key={v[0]}><Link className='nav-link' to={v[1]}>{v[0]}</Link></li>
                    }
                })}
                </ul>


                <div className={open?'drawer-button drawer-closed':'drawer-button drawer-opened'+(scrollPosition > 40 && ' bars-background')} onClick={onClick}><FontAwesomeIcon icon={solid("bars")} color={'gainsboro'} /></div>
                <ul className={open?'nav-drawer drawer-opened':'nav-drawer drawer-closed'}>
                    <li className='drawer-button-x' onClick={onClick}><FontAwesomeIcon icon={solid("times")} color={'gainsboro'} /></li>
                    <div className='drawer-nav'>
                        {CONFIG.WEBSITE.NAV.map((v) => {
                            return <li className='nav-item' onClick={close} key={v[0]}><Link className='nav-link' to={v[1]}>{v[0]}</Link></li>
                        })}
                    </div>
                </ul>
            </nav>
        </header>
    )
}

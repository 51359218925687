import React from 'react'
import '../styles/Services.css'

export default function Services() {
  return (
    <main>
      <section className='services-section'>
        <h1 className='services-section-title'>SERVICES</h1>
        <div className='services'>
          <a className='service' target='_blank' href="https://www.fiverr.com/s/r82Yvx">
            <h1 className='service-title half-color' attribute='VOCAL MIX'>VOCAL MIX</h1>
            <div className='service-container service-mixvocals' />
            <p className='service-description'>VOCAL MIXING</p>
          </a>

          <a className='service' target='_blank' href="https://www.fiverr.com/s/mj34Pb">
            <h1 className='service-title half-color' attribute='SONG MASTER'>SONG MASTER</h1>
            <div className='service-container service-mastersong' />
            <p className='service-description'>SONG MASTERING</p>
          </a>

          <a className='service' target='_blank' href="https://www.fiverr.com/s/2qk3Xr">
            <h1 className='service-title half-color' attribute='FEATURE'>FEATURE</h1>
            <div className='service-container service-feature'></div>
            <p className='service-description'>FEATURE</p>
          </a>

          <a className='service' target='_blank' href="https://www.fiverr.com/s/3pZDDx">
            <h1 className='service-title half-color' attribute='WRITE VERSE'>WRITE VERSE</h1>
            <div className='service-container service-writeverse' />
            <p className='service-description'>SONG WRITING</p>
          </a>

        </div>

        <div className='services-access'>
          <a className='services-link' target='_blank' href="https://www.fiverr.com/raceouzi">
            <img className='fiverr-logo' src="https://freelogopng.com/images/all_img/1656738037fiverr-icon-png.png" alt="" />
            <p>@raceouzi</p>
          </a>
        </div>
      </section>
    </main>
  )
}

import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'

import Home from './pages/Home'

import Contact from './pages/Contact'
import Merch from './pages/Merch'
import Services from './pages/Services'
import Blank from './pages/Blank'


import { BrowserRouter, Routes, Route } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />} >
        <Route index element={<Home/>} />
        <Route path="home" element={<Home />} />
        <Route path="services" element={<Services />} />
        <Route path="contact" element={<Contact />} />
        <Route path="merch" element={<Merch />} />
        <Route path="*" element={<Blank/>} />
      </Route>
    </Routes>
  </BrowserRouter>
)
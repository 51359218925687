import React, {useEffect, useState} from 'react'
import '../styles/Home.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faL } from '@fortawesome/free-solid-svg-icons'
import { faSpotify, faApple, faYoutube, faSoundcloud } from '@fortawesome/free-brands-svg-icons'

export default function Home() {

    const [track, setTrack] = useState(1)
    const [animation, setAnimation] = useState(false)
    const tracks = [
        {
            img: '/wua.png',
            title: 'where u at?',
            links: {
                genius: 'https://genius.com/Race-ouzi-where-u-at-lyrics',
                youtube: 'https://www.youtube.com/watch?v=FQSVXYgoHw8',
                youtube_id: 'FQSVXYgoHw8',
                spotify: 'https://open.spotify.com/track/7pzKNWeftsRaA6wWrtPlT5',
                apple: 'https://music.apple.com/us/album/where-u-at/1679499650',
                soundcloud: 'https://soundcloud.com/raceouzi/where-u-at'
            },
            gradient: 'blue'
        },
        {
            img: '/raul.png',
            title: 'run after ur love',
            links: {
                genius: 'https://genius.com/Race-ouzi-run-after-ur-love-lyrics',
                youtube: 'https://www.youtube.com/watch?v=s2l6nH7BigY',
                youtube_id: 's2l6nH7BigY',
                spotify: 'https://open.spotify.com/track/0tc86cwfpaCo9YR0YVhCEr',
                apple: 'https://music.apple.com/ca/album/run-after-ur-love/1675573987',
                soundcloud: 'https://soundcloud.com/raceouzi/run-after-ur-love'
            },
            gradient: 'pink'
        }
    ]

    function onChangeTrack(e) {
        setAnimation(true)

        let index = track
        if (e && e.deltaY < 0) index = track <= 0 ? tracks.length-1 : track-1
        else index = track >= tracks.length-1 ? 0 : track+1
        setTimeout(() => {
            setTrack(index)
        }, 50)
        setTimeout(() => {
            setAnimation(false)
        }, 501)
    }
    return (
        <main className={`gradient-${tracks[track].gradient}`} onWheel={onChangeTrack}>
           <div className={`curtain${animation ? ' curtain-animation': ''}`} />
            <div className="release">
                <iframe
                    className='iframe'
                    width="1519"
                    height="526"
                    src={`https://www.youtube.com/embed/${tracks[track].links.youtube_id}?rel=0&color=white&autoplay=1&mute=1&loop=1&playlist=${tracks[track].links.youtube_id}`}
                    title="run after ur love"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; web-share; fullscreen"
                    referrerpolicy="strict-origin-when-cross-origin"
                    allowfullscreen
                >
                </iframe>
                <div className="info">
                    <p className="label">LATEST RELEASES</p>
                    <h1 className='track-title'>{tracks[track].title}</h1>
                    <h2 className='track-artist'>race ouzi</h2>
                    <a className='track-lyrics' href={tracks[track].links.genius} target="_blank" rel="noreferrer">Lyrics</a>
                </div>
            </div>
            <div className="links">
                <a className='btn-link' href={tracks[track].links.spotify} target="_blank" rel="noreferrer"><div className="app-div"><FontAwesomeIcon icon={faSpotify} color='black' size='2xl' /></div></a>
                <a className='btn-link' href={tracks[track].links.apple} target="_blank" rel="noreferrer"><div className="app-div"><FontAwesomeIcon icon={faApple} color='black' size='2xl' /></div></a>
                <a className='btn-link' href={tracks[track].links.youtube} target="_blank" rel="noreferrer"><div className="app-div"><FontAwesomeIcon icon={faYoutube} color='black' size='xl' /></div></a>
                <a className='btn-link' href={tracks[track].links.soundcloud} target="_blank" rel="noreferrer"><div className="app-div"><FontAwesomeIcon icon={faSoundcloud} color='black' size='xl' /></div></a>
                <div className='arrow' onClick={onChangeTrack}>
                    <FontAwesomeIcon icon={faChevronDown} bounce size='2xl' />
                </div>
            </div>
        </main>   
    )
}

/*
 <a className="release" href={tracks[track].links.youtube} target="_blank" rel="noreferrer">
    <img className="coverart" src={tracks[track].img} alt="" />
    <div className="info">
        <p className="label">LATEST RELEASES</p>
        <h1 className='track-title'>{tracks[track].title}</h1>
        <h2 className='track-artist'>race ouzi</h2>
        <a className='track-lyrics' href={tracks[track].links.genius} target="_blank" rel="noreferrer">Lyrics</a>
    </div>
</a>
*/